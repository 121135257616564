import React, { useContext } from "react";
import Helper from "./../../../extra/helper";
import { AuthContext } from "./../../../context/AuthProvider";

function ExportProviderTable(props) {
  const { provider } = useContext(AuthContext);
  const getTitleById = (id) => {
    const result = provider.filter((item) => item.id === id);
    return result.length > 0 ? result[0].title : null; // Trả về title nếu tìm thấy, ngược lại trả về null
  };

  return (
    <div style={{ display: "none" }}>
      <table
        className="collapse-border"
        id={"report"}
        data-cols-width={props?.acountGC ? "10,30,40,30, 30, 30, 30, 30,30, 40,40,20, 40, 40,30,30, 40,40,40,40,40,40" : "10,30,40,30, 30,30, 30, 30,40, 30, 40, 20, 40, 40, 40, 30,30,30,40, 40"}
        border={"1px solid red"}
      >
        <thead style={{ fontSize: "13" }}>
          <tr>
            <th
              // data-b-a-c="FFFFFFFF"
              colSpan={13}
              data-f-name={"Times new roman"}
              data-f-sz={"24"}
              data-a-wrap={true}
              data-a-h={"center"}
              // data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Quản lý đơn
            </th>
          </tr>
          <tr>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              STT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Mã giỏ hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Mã đơn
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên Sản phẩm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Công ty bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Số hợp đồng bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Hiệu lực bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Loại đơn
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Chủ hợp đồng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Người được bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Số điện thoại
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Gói sản phẩm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Khuyến mãi
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tổng phí bảo hiểm(bao gồm khuyến mãi)
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tổng phí bảo hiểm(sau khuyến mãi)
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày tạo
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày khách hàng thanh toán phí
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày cấp GCN
            </th>
            {props?.acountGC && (
              <>
                <th
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-f-bold={true}
                >
                  Mã CTV
                </th>
                <th
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-f-bold={true}
                >
                  Tên CTV
                </th>
                <th
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-f-bold={true}
                >
                  Kênh bán
                </th>
              </>
            )}
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Trạng thái
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Hình thức thanh toán
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13" }}>
          {props?.data?.map((item, index) => {
            return (
              <tr key={index} style={{ fontSize: "13" }}>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {index + 1}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.transaction_code}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.code}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.product_by_provider_title || item?.product_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {getTitleById(item?.provider_id)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.cert_num}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.begin_date) + "-" + Helper._formatDayNoHour(item?.end_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  Cấp mới
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.buyer_name
                    ?.split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredName
                            ?.split(" ")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredName
                        ?.split(" ")
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    item?.insured_persons?.length > 0 ?
                      item?.insured_persons?.map((person, index) => (
                        <React.Fragment key={index}>
                          {person?.insuredPhone}
                          {index < item?.insured_persons?.length - 1 && <br />}
                        </React.Fragment>
                      )) :
                      item?.insured_persons?.insuredPhone
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.packages?.[0]?.title || item?.packages?.title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.discount_amount}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.fees}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {+item?.fees - +item?.discount_amount}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.created_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.payment_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.updated_date)}
                </td>
                {props?.acountGC && (
                  <>
                    {" "}
                    <td
                      data-f-name={"Times new roman"}
                      data-f-sz={"16"}
                      data-b-a-s={"thin"}
                    >
                      {item?.extra_info?.referalCode}
                    </td>
                    <td
                      data-f-name={"Times new roman"}
                      data-f-sz={"16"}
                      data-b-a-s={"thin"}
                    >
                      {item?.extra_info?.referalCode ? item?.user_full_name : ''}
                    </td>
                    <td
                      data-f-name={"Times new roman"}
                      data-f-sz={"16"}
                      data-b-a-s={"thin"}
                    >
                      {
                        item?.merchant_id === 6 ? 'GSale' :
                          item?.merchant_id === 66 ? "GC Direct Sale" :
                            item?.merchant_id === 69 ? "GlobeDr" :
                              'Kênh ' + item?.merchant_id
                      }
                    </td>
                  </>
                )}
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {
                    +item?.status === 1
                      ? "Chờ thanh toán"
                      : +item?.status === 10
                        ? "Chờ GCN"
                        : +item?.status === 3
                          ? "Hoàn thành"
                          : +item?.status === 4
                            ? 'Đơn Huỷ'
                            : +item?.status === 2
                              ? 'Đã thanh toán'
                              : +item?.status === 7
                                ? "Lỗi cấp GCN"
                                : +item?.status === 5
                                  ? "Lỗi thanh toán"
                                  : "Chờ thẩm định"
                  }
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.extra_info?.paymentType}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(ExportProviderTable);
